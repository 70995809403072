import { useRef, useState } from "react";
import useFade from "../../../MainComponents/Hooks/useFade";
import SingleCourse from "../../../MainComponents/SingleCourse";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "../../../MainComponents/GlobalContext.jsx";
import SingleChapter from "../../../MainComponents/SingleChapter";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader";
import HelmetTags from "../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronCircleUp,
} from "@fortawesome/free-solid-svg-icons";

function StudentHomework() {
  //!---- get all lectures  -------
  const [allHomeWorksData, allHomeWorksErrors, allHomeWorksLoading] = useAxios(
    "https://api.amirashaltout.com/api/homework",
    "GET",
    "GET"
  );

  const TitleWrapper = useRef();
  const [fade] = useFade(TitleWrapper, 100);
  if (allHomeWorksLoading) {
    return <Loader />;
  }

  if (allHomeWorksErrors) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{allHomeWorksErrors}</p>
      </div>
    );
  }

  return (
    <section className=" width  mb-32 h-full  dark:bg-dark dark:text-light">
      <HelmetTags title="HomeWorks | Ms Amira Shaltout"></HelmetTags>
      <div ref={TitleWrapper} className="title-wrapper  mt-36">
        <h2
          className={`mb-12 text-center text-5xl font-bold transition-all duration-300 ease-linear ${
            fade ? "translate-y-14 opacity-0" : "translate-y-0 opacity-100"
          } `}
        >
          HomeWorks
        </h2>
      </div>

      {/* Button to reverse the order */}

      <div className="lectures grid-auto-fit">
        {allHomeWorksData?.data?.length === 0 ? (
          <p>No HomeWork</p>
        ) : (
          allHomeWorksData?.data.map((Card) => (
            <SingleCourse
              lecture={Card}
              key={Card.key}
              type={"homeworkstudent"}
            />
          ))
        )}
      </div>
    </section>
  );
}

export default StudentHomework;
