import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthState } from "../../../../MainComponents/GlobalContext";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";

function AdminExams() {
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const [allExamsData, allExamsErrors, allExamsloading] = useAxios(
    process.env.REACT_APP_ADMIN_TRIAL_EXAMS_TAB_API,
    "GET",
    "GET",
    reFetch
  );
  const [academicSection, setAcademicSection] = useState("all");
  const filteredPractise = allExamsData?.data?.filter((lecture) => {
    if (academicSection === "all") return true;

    if (academicSection === "visibletoall") return lecture.visibility === 1;
    return lecture.section === academicSection; // Filter by stage
  });
  if (allExamsloading) return <Loader />;
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 sm:px-10 ">
      <HelmetTags title="Exams | Ms Amira Shaltout"></HelmetTags>
      <div className="mb-32 flex flex-col gap-10">
        <h2 className="w-full text-4xl font-bold md:text-center">Exams</h2>{" "}
        <div className="flex items-center gap-6">
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("exam_add")
            )) && (
            <Link
              to="/admin/exams/add-quiz"
              className="rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add Quiz
            </Link>
          )}
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("exam_add")
            )) && (
            <Link
              to="/admin/exams/add-exam"
              className="rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              Add Exam
            </Link>
          )}
        </div>
        <div className="flex w-full flex-wrap py-10 gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
          <button
            onClick={() => {
              setAcademicSection("all");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academicSection === "all" && "bg-secondary text-light"
            }`}
          >
            All Practise
          </button>
          <button
            onClick={() => {
              setAcademicSection("est");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academicSection === "est" && "bg-secondary text-light"
            }`}
          >
            EST
          </button>

          <button
            onClick={() => {
              setAcademicSection("act");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academicSection === "act" && "bg-secondary text-light"
            }`}
          >
            ACT
          </button>
          <button
            onClick={() => {
              setAcademicSection("dsat");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academicSection === "dsat" && "bg-secondary text-light"
            }`}
          >
            DIGITAL SAT
          </button>
          <button
            onClick={() => {
              setAcademicSection("visibletoall");
            }}
            className={`min-w-[200px] rounded-xl border-2 border-secondary p-4 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academicSection === "visibletoall" && "bg-secondary text-light"
            }`}
          >
            Visible To All
          </button>
        </div>
        <div className="lectures grid-auto-fit w-full">
          {filteredPractise?.map((Card) => (
            <SingleCourse
              reFetch={reFetch}
              setReFetch={setReFetch}
              admin
              exam={Card.is_exam === 1 ? true : false}
              quiz={Card.is_exam === 0 ? true : false}
              lecture={Card}
              key={Card.key}
              type={"exam"}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default AdminExams;
