import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import ModalImage from "react-modal-image";

const Question = ({ state, dispatch, homework, exam }) => {
  const { ID } = useParams();
  const essayAnswerRef = useRef();
  const fileInputRef = useRef();
  const [preview, setPreview] = useState(null);

  const checkApi = homework
    ? process.env.REACT_APP_STUDENT_HOMEWORK_UPDATE_API
    : exam
      ? process.env.REACT_APP_STUDENT_TRIAL_EXAM_UPDATE_API
      : process.env.REACT_APP_STUDENT_QUIZZ_UPDATE_API;

  const handleSelectAnswer = (answer, questionId, n) => {
    dispatch({
      type: "setAnsweredQuestions",
      payload: {
        id: questionId,
        order: answer,
        is_essay: 0,
        n,
        // file: fileInputRef.current.files[0],
      },
    });
    async function fetchData() {
      try {
        let res = await axios.post(checkApi, {
          id: questionId,
          order: answer,
          lecture_id: ID,
          is_essay: 0,
          n: n,
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.response.data.message);
      }
    }

    fetchData();

    dispatch({
      type: "setIsSelectedAnswer",
      payload: { questionId, answer, n },
    });
  };

  const handleOnBlurEssayAnswer = (questionId, n) => {
    dispatch({
      type: "setAnsweredQuestions",
      payload: {
        id: questionId,
        order: essayAnswerRef.current.value,
        is_essay: 1,
        n,
      },
    });

    async function fetchData() {
      try {
        let res = await axios.post(checkApi, {
          id: questionId,
          order: essayAnswerRef.current.value,
          lecture_id: ID,
          is_essay: 1,
          n: n,
        });
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.response.data.message);
      }
    }

    fetchData();
  };

  const handleFileChange = async () => {
    const file = fileInputRef.current.files[0];
    if (!file) return;

    // Set image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);

    const questionId = state.questions[state.currentQuestionIndex].id;
    const order = essayAnswerRef.current.value;

    const formData = new FormData();
    formData.append("id", questionId);
    formData.append("file", file);
    formData.append("lecture_id", ID);
    formData.append("is_essay", 1);
    formData.append("order", order);

    try {
      await axios.post(checkApi, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch({
        type: "setAnsweredQuestions",
        payload: {
          id: questionId,
          order,
          is_essay: 1, // Assuming 1 indicates an essay
          file,
        },
      });
      toast.success("File uploaded successfully");
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.response?.data?.message ||
          "An error occurred while uploading the file"
      );
    }
  };

  useEffect(() => {
    const currentAnswerd = state.answeredQuestions.filter(
      (question) =>
        question?.id === state?.questions[state.currentQuestionIndex]?.id
    )[0];
    // if not essay question set textarea by the essay question value
    if (!state?.questions[state.currentQuestionIndex]?.answers) {
      if (
        state?.questions[state.currentQuestionIndex]?.answer ||
        currentAnswerd?.order
      ) {
        essayAnswerRef.current.value = currentAnswerd?.order
          ? currentAnswerd?.order
          : state?.questions[state.currentQuestionIndex]?.answer;
      } else {
        essayAnswerRef.current.value = "";
      }
    }
  }, [state.answeredQuestions, state.currentQuestionIndex, state?.questions]);

  return (
    <div className="container max-w-[600px]">
      <div className="current-question flex-row">
        {`${state.currentQuestionIndex + 1}.`}
        <div
          dangerouslySetInnerHTML={{
            __html:
              state.questions[state.currentQuestionIndex]?.question ||
              "No question available",
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html:
              state.paragraphs?.[state.currentQuestionIndex]?.paragraph || "",
          }}
        />
        <img
          src={state.paragraphs?.[state.currentQuestionIndex]?.paragraph || ""}
          alt=""
        />
      </div>

      <div className="current-question-degree flex items-center justify-end">
        <p>
          {state.questions[state.currentQuestionIndex]?.degree || "No degree"}{" "}
          Degree
        </p>
      </div>

      <div className="current-question-answers">
        {state?.questions[state.currentQuestionIndex]?.answers ? (
          <>
            {state?.questions[state.currentQuestionIndex]?.answers?.map(
              (answer, i) => (
                <div
                  key={i}
                  className={`${
                    state.questions[state.currentQuestionIndex]
                      .selected_answer === answer.order
                      ? "selected"
                      : ""
                  }`}
                  onClick={() =>
                    handleSelectAnswer(
                      answer.order,
                      state.questions[state.currentQuestionIndex].id,
                      state.questions[state.currentQuestionIndex].n
                    )
                  }
                >
                  <span>{answer.order} </span>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: answer.answer || "No answer provided",
                    }}
                  />
                </div>
              )
            )}
          </>
        ) : (
          <>
            <textarea
              ref={essayAnswerRef}
              onBlur={() =>
                handleOnBlurEssayAnswer(
                  state.questions[state.currentQuestionIndex]?.id,
                  state.questions[state.currentQuestionIndex]?.n
                )
              }
              placeholder="Write your answer"
              className="textareaQuiz !border-secondary !border-2 rounded-xl p-4 !placeholder:text-slate-400"
            ></textarea>
            <label
              htmlFor="formFileLg"
              className="mt-6 inline-block text-neutral-500 dark:text-neutral-400"
            >
              Upload Draft
            </label>
            <input
              className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-secondary-500 bg-transparent bg-clip-padding px-3 py-[0.32rem] text-base font-normal leading-[2.15] text-surface transition duration-300 ease-in-out"
              id="formFileLg"
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </>
        )}
      </div>

      {!state?.questions[state.currentQuestionIndex]?.answers || preview ? (
        <div className="flex flex-wrap items-center justify-center">
          <ModalImage
            small={
              preview
                ? preview
                : state?.questions[state.currentQuestionIndex]?.draft || ""
            }
            large={
              preview
                ? preview
                : state?.questions[state.currentQuestionIndex]?.draft || ""
            }
            className="h-[300px] object-cover mt-10"
          />
        </div>
      ) : null}
    </div>
  );
};

export default Question;
