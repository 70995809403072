import React from "react";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useParams } from "react-router-dom";

export default function AllStudentsHomework() {
  const { chapterID } = useParams();
  const table = useTable("homeworkallstudent", "", "", "", "", chapterID);
  return (
    <section className=" flex flex-col px-10 py-10 ">
      <div>{table}</div>
    </section>
  );
}
