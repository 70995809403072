import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "./GlobalContext.jsx";
import { Popconfirm } from "antd";
import { useEffect, useState } from "react";
import useAxios from "./Hooks/useAxios.jsx";
import useCountDown from "./Hooks/useCountDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";

function SingleCourse({
  lecture,
  admin,
  exam,
  reFetch,
  setReFetch,
  notSigned,
  isExam,
  course,
  editLink,
  studentsLink,
  singleLink,
  type,
  myCourses,
  quiz,
}) {
  /*   const { days, hours, minutes, seconds } = useCountDown(lecture.expire_at); */
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  //!--------- delete Lecture -------
  const apiDelete = exam
    ? process.env.REACT_APP_ADMIN_DELETE_TRIAL_API
    : quiz
      ? process.env.REACT_APP_ADMIN_DELETE_TRIAL_API
      : course
        ? process.env.REACT_APP_ADMIN_DELETE_COURSE_API
        : process.env.REACT_APP_ADMIN_DELETE_LECTURE_API;

  const [deleteLectureSuccess, deleteLectureErrors, deleteloading] = useAxios(
    apiDelete,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteLecture(id) {
    setSubmitDelete({
      flag: "deleteLecture",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  useEffect(() => {
    if (deleteLectureSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteLectureSuccess]);

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="mb-8"
    >
      <div class="min-h-[350px] w-[350px] bg-white shadow-lg sm:w-[300px] relative flex flex-col rounded-[25px] bg-clip-border text-gray-700">
        <div class="relative mx-4 -mt-8 h-[240px] overflow-hidden rounded-[25px] bg-blue-gray-500 bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-blue-500 to-blue-600">
          <img
            onClick={() =>
              navigate(
                `${
                  singleLink
                    ? singleLink
                    : admin
                      ? `${
                          isExam
                            ? `/admin/exams/${lecture?.key}`
                            : course
                              ? `/admin/${type}/${lecture?.key}`
                              : `/admin/${type}/${lecture?.key}/add-placement-test`
                        }`
                      : `${
                          exam
                            ? `/home/exams/${lecture?.key}`
                            : `/home/${
                                myCourses ? "my-courses" : "courses"
                              }/sessions/${lecture?.key}`
                        }`
                }`
              )
            }
            className="h-[240px] w-full object-cover  duration-300 hover:scale-125"
            src={lecture?.img}
            alt={lecture?.title}
          />
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_show_creator")
            )) &&
            admin &&
            !exam && (
              <div className="creator pointer-events-none absolute right-4 top-4 flex h-10 w-fit items-center justify-center rounded-xl bg-secondary px-3 text-xl font-semibold ">
                {lecture.section === null ? "All" : lecture.section}{" "}
                {lecture?.created_by && `By ${lecture?.created_by} `}
              </div>
            )}
        </div>
        <div class="p-6">
          <h3
            onClick={() =>
              navigate(
                `${
                  singleLink
                    ? singleLink
                    : admin
                      ? `${
                          isExam
                            ? `/admin/exams/${lecture?.key}`
                            : course
                              ? `/admin/${type}/${lecture?.key}`
                              : `/admin/${type}/${lecture?.key}/add-placement-test`
                        }`
                      : `${
                          exam
                            ? `/home/exams/${lecture?.key}`
                            : `/home/${
                                myCourses ? "my-courses" : "courses"
                              }/sessions/${lecture?.key}`
                        }`
                }`
              )
            }
            className={`title mb-7 w-fit cursor-pointer text-secondary ${
              admin ? "text-end" : "text-center"
            }   text-2xl  font-bold`}
          >
            {lecture?.name?.substring(0, 33)}
          </h3>
          <p
            dir="ltr"
            className="description text-start text-2xl font-semibold leading-relaxed text-secondary/70"
          >
            {lecture?.description?.substring(0, 70)}...
          </p>
        </div>
        <div class="p-6 pt-0">
          {/*   {!admin && !exam && (
            <>
              {lecture.is_buy === 1 && (
                <div className="w-full font-bold py-2 border-t-2 border-b-2 border-secondary rounded-md mt-4">
                  <p className="mb-2">Expire After </p>
                  <p className="text-center text-2xl">
                    {days} Days | {hours} Hours | {minutes} Miniutes | {seconds}
                    Seconds
                  </p>
                </div>
              )}
            </>
          )}
 */}
          <div
            className={`visibility-cta mt-5 flex w-full   ${
              admin ? "justify-between" : "justify-center"
            } flex-col items-center pb-4 pt-12 gap-5`}
          >
            {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              AuthState.userData?.admin?.permissions?.some((permission) =>
                permission.name.startsWith("lecture")
              )) &&
              admin && (
                <div className="visibility pointer-events-none flex h-10 w-fit  items-center justify-center rounded-xl bg-secondary px-3 text-xl font-semibold text-light">
                  {lecture?.visibility === 0 ? "Hidden" : "Visible"}
                </div>
              )}
            {admin ? (
              <div className="admin-options flex w-full justify-center items-center gap-5 flex-wrap ">
                {exam || course ? (
                  <Link
                    to={
                      studentsLink
                        ? studentsLink
                        : isExam
                          ? `/admin/exams/all-students/${lecture?.key}`
                          : course
                            ? `/admin/${type}/all-students/${lecture?.key}`
                            : `/admin/quizzes/all-students/${lecture?.key}`
                    }
                    className="rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                  >
                    Students
                  </Link>
                ) : null}

                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith(
                      `${exam ? "exam_edit" : "lecture_edit"}`
                    )
                  )) &&
                  admin && (
                    <Link
                      to={`/admin/${type}/${lecture.key}/add-placement-test`}
                      className="rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                    >
                      Placement Test
                    </Link>
                  )}

                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith(
                      `${exam ? "exam_edit" : "lecture_edit"}`
                    )
                  )) &&
                  admin && (
                    <Link
                      to={`${
                        editLink
                          ? editLink
                          : isExam
                            ? `/admin/exams/edit-exam/${lecture?.key}`
                            : course
                              ? `/admin/${type}/edit-course/${lecture?.key}`
                              : `/admin/quizzes/edit-quiz/${lecture?.key}`
                      }`}
                      className="w-24 rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                    >
                      Edit
                    </Link>
                  )}
                {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                  AuthState.userData?.admin?.permissions?.some((permission) =>
                    permission.name.startsWith(
                      `${exam ? "exam_delete" : "lecture_delete"}`
                    )
                  )) && (
                  <Popconfirm
                    okText="Confirm"
                    okType="danger"
                    cancelText="Cancel"
                    color="#fecaca"
                    //okButtonProps={{ loading: loading }}
                    onConfirm={() => handleDeleteLecture(lecture?.key)}
                    title={
                      exam
                        ? "Do you really want to delete the Exam?"
                        : "Do you really want to delete the lecture?"
                    }
                  >
                    <button className="rounded-2xl border-2  border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                      Delete
                    </button>
                  </Popconfirm>
                )}
              </div>
            ) : (
              <>
                <Link
                  to={
                    notSigned
                      ? "/signin"
                      : `${
                          exam
                            ? `/home/exams/sessions/solve-exam/${lecture?.key}`
                            : `/home/${
                                myCourses ? "my-courses" : "homework"
                              }/sessions/solve-homework/${lecture?.key}`
                        }`
                  }
                  className="w-full text-center tp-btn  d-xl-block tp-btn-hover "
                >
                  {exam ? "View Exam" : "View HomeWork"}
                  <span className="ml-3">
                    <FontAwesomeIcon icon={faLongArrowRight} />
                  </span>
                  <b></b>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default SingleCourse;
