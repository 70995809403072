import { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../../../../MainComponents";

const AdminAddEditExam = ({ edit, quiz, exam }) => {
  const [reFetch, setReFetch] = useState(false);
  const [values, setValues] = useState(null);
  const [data, setData] = useState([]);
  const [linkedPractise, setLinkedPractise] = useState("est");
  const { ID } = useParams();
  const [allExamsData, allExamsErrors, allExamsloading] = useAxios(
    process.env.REACT_APP_ADMIN_TRIAL_EXAMS_TAB_API,
    "GET",
    "GET",
    reFetch
  );

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditExam": {
        return {
          ...state,
          submitAddEditExam: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    submitAddEditExam: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_TRIAL_EXAM_API
    : process.env.REACT_APP_ADMIN_ADD_TRIAL_EXAM_API;

  //!--------- add edit exam -------
  const [ExamAddEditSuccess, ExamAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditExam.flag,
    state.submitAddEditExam.dependency,
    state.submitAddEditExam.data,
    true
  );

  //!--------- get the exam info for editing -------

  const [ExamInfo, ExamInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_INFO_API}/${ID}`,
    "GET",
    ID,
    ID
  );

  useEffect(() => {
    if (ExamInfo) {
      let temp = ExamInfo.data;
      delete temp.img;
      setValues({
        ...temp,
        visibility: temp.visibility === 1 ? true : false,
        nextlecture: temp.nextlecture === null ? 0 : temp.nextlecture,
        edu_type: String(temp.edu_type),
      });
    }
  }, [ExamInfo]);

  useEffect(() => {
    if (ExamAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [ExamAddEditSuccess]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm({ mode: "onTouched", values });
  const watchsection = watch("section");
  const onSubmit = (data) => {
    const finalData = ID
      ? {
          ...data,
          section: watchsection === "all" ? null : watchsection,
          id: ID,
          img: data?.img[0] || null,
          visibility: +data?.visibility,
          v_model: +data?.v_model,
          is_buying: +data?.is_buying,
          // is_exam: quiz ? 0 : 1,
        }
      : {
          ...data,
          section: data?.section === "all" ? null : data?.section,
          visibility: +data?.visibility,
          img: data?.img[0] || null,
          is_exam: quiz ? 0 : 1,
        };
    dispatch({
      type: "setSubmitAddEditExam",
      payload: {
        flag: "AddEditLecture",
        dependency: !state.submitAddEditExam.dependency,
        data: finalData,
      },
    });
  };
  useEffect(() => {
    const filteredPractise = allExamsData?.data?.filter((lecture) => {
      if (watchsection === "all") return true;

      return lecture.section === watchsection; // Filter by stage
    });
    setData(filteredPractise);
  }, [watchsection, allExamsData]);
  if (allExamsloading) return <Loader />;
  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Exams | Ms Amira Shaltout"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {ID ? "Edit Exam" : "Please fill out the information to add the test"}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-fit  w-3/4 md:w-full flex-col  items-center justify-start gap-6 rounded-3xl bg-white p-10 shadow-3xl md:w-[250px] "
        >
          {/** Name price filed */}
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="name flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className="w-full text-end  text-xl text-blue-900  ">
                  {errors.name.type === "required" &&
                    "Please fill out this field"}
                  {errors.name.type === "pattern" &&
                    "Only English letters are allowed"}
                  {errors.name.type === "maxLength" &&
                    "The maximum number of characters is 40 characters"}
                  {errors.name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.name && (
                    <p className="w-full text-end  text-xl text-blue-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
            <div className="flex w-1/2 flex-col items-start justify-center gap-2  md:w-full">
              <label htmlFor="section">Section</label>
              <select
                name="section"
                id="section"
                placeholder="Select Section"
                {...register("section", { required: true })}
              >
                <option value="est">EST</option>
                <option value="act">ACT</option>
                <option value="dsat">DIGITAL SAT</option>
                <option value="all">All</option>
              </select>

              {errors.section && (
                <p className="text-xs text-red-500 ">
                  {errors.section === "required" && "This field is required."}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.section && (
                    <p className="w-full text-end text-xs text-red-500  ">
                      {ExamAddEditErrors?.response?.data?.errors?.section[0]}
                    </p>
                  )
              }
            </div>
          </div>
          {data?.length !== 0 ? (
            <div className="flex flex-col items-start gap-5 w-full">
              <label htmlFor="linked">Linked Practise</label>
              <select
                name="linked"
                id="linked"
                placeholder="Select Practise"
                {...register("prev_exam", { required: true })}
              >
                {data?.map((e) => (
                  <>
                    <option value={e.key}>{e.name}</option>
                  </>
                ))}
              </select>

              {errors.section && (
                <p className="text-xs text-red-500 ">
                  {errors.section === "required" && "This field is required."}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.section && (
                    <p className="w-full text-end text-xs text-red-500  ">
                      {ExamAddEditErrors?.response?.data?.errors?.section[0]}
                    </p>
                  )
              }
            </div>
          ) : null}

          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            {/* success_rate */}
            {/* <div className="w-1/2 flex flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="success_rate">Success Rate</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="number"
                id="success_rate"
                placeholder="Success Rate"
                name="success_rate"
                autoComplete="on"
                min={0}
                max={100}
                {...register("success_rate", {
                  required: true,
                  minLength: 1,
                })}
              />
              {errors.success_rate && (
                <p className="w-full text-end  text-xl text-blue-900  ">
                  {errors.success_rate.type === "required" &&
                    "Please fill out this field"}
                  {errors.success_rate.type === "minLength" &&
                    "Please type at least 1 character"}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.success_rate && (
                    <p className="w-full text-end  text-xl text-blue-900  ">
                      {
                        ExamAddEditErrors?.response?.data?.errors
                          ?.success_rate[0]
                      }
                    </p>
                  )
              }
            </div> */}
            {/* duration */}
            <div className="w-full md:w-full flex  flex-col items-start justify-center  gap-2">
              <label htmlFor="duration">Duration</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="number"
                id="duration"
                placeholder="Duration"
                name="duration"
                autoComplete="on"
                min={0}
                {...register("duration", {
                  required: true,
                  minLength: 1,
                })}
              />
              {errors.duration && (
                <p className="w-full text-end  text-xl text-blue-900  ">
                  {errors.duration.type === "required" &&
                    "Please fill out this field"}
                  {errors.duration.type === "minLength" &&
                    "Please type at least 1 character"}
                </p>
              )}

              {errors.lecture && (
                <p className=" text-xl text-blue-900 ">
                  {errors.lecture.type === "required" &&
                    "Please fill out this field"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.lecture && (
                    <p className="w-full text-end  text-xl text-blue-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.lecture[0]}
                    </p>
                  )
              }
            </div>
            <div className="w-full md:w-full flex  flex-col items-start justify-center  gap-2">
              <label htmlFor="success_rate">Success Rate</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="number"
                id="success_rate"
                placeholder="Success Rate"
                name="success_rate"
                autoComplete="on"
                min={0}
                {...register("success_rate", {
                  required: true,
                  minLength: 1,
                })}
              />
              {errors.success_rate && (
                <p className="w-full text-end  text-xl text-blue-900  ">
                  {errors.success_rate.type === "required" &&
                    "Please fill out this field"}
                  {errors.success_rate.type === "minLength" &&
                    "Please type at least 1 character"}
                </p>
              )}

              {errors.success_rate && (
                <p className=" text-xl text-blue-900 ">
                  {errors.success_rate.type === "required" &&
                    "Please fill out this field"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.lecture && (
                    <p className="w-full text-end  text-xl text-blue-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.lecture[0]}
                    </p>
                  )
              }
            </div>
          </div>
          {/** visibility homework quiz fields */}
          {edit && (
            <div className="flex  w-full items-  gap-16 lg:flex-col md:gap-10 ">
              {!exam && (
                <div className=" visibility flex w-1/3 lg:w-full flex-col items-end justify-center gap-2">
                  <div className="flex w-full items-center justify-end gap-10">
                    <label className="visibility-switch">
                      <input
                        className=""
                        id="is_buying"
                        name="is_buying"
                        {...register("is_buying", {
                          required: false,
                        })}
                        type="checkbox"
                      />
                      <span className="visibility-slider">
                        <svg
                          className="slider-icon"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <path fill="none" d="m4 16.5 8 8 16-16"></path>
                        </svg>
                      </span>
                    </label>
                    <div>
                      <label
                        className="w-full cursor-pointer truncate"
                        htmlFor="is_buying"
                      >
                        Enable Buying
                      </label>
                    </div>
                  </div>

                  {errors.is_buying && (
                    <p className=" text-xl text-blue-900 ">
                      {errors.is_buying.type === "required" &&
                        "Please fill out this field"}{" "}
                    </p>
                  )}
                  {
                    //!-------server errors -----

                    ExamAddEditErrors &&
                      ExamAddEditErrors?.response?.data?.errors?.is_buying && (
                        <p className="w-full text-end  text-xl text-blue-900  ">
                          {
                            ExamAddEditErrors?.response?.data?.errors
                              ?.is_buying[0]
                          }
                        </p>
                      )
                  }
                </div>
              )}
              <div className=" visibility flex w-1/3  lg:w-full flex-col items-end justify-center gap-2">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="v_model"
                      name="v_model"
                      {...register("v_model", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="v_model"
                    >
                      Show Answer{" "}
                    </label>
                  </div>
                </div>

                {errors.v_model && (
                  <p className=" text-xl text-blue-900 ">
                    {errors.v_model.type === "required" &&
                      "Please fill out this field"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.v_model && (
                      <p className="w-full text-end  text-xl text-blue-900  ">
                        {ExamAddEditErrors?.response?.data?.errors?.v_model[0]}
                      </p>
                    )
                }
              </div>
              <div className=" visibility flex w-1/3  lg:w-full flex-col items-end justify-center gap-2">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="visibility"
                      name="visibility"
                      {...register("visibility", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="visibility"
                    >
                      Visibility{" "}
                    </label>
                  </div>
                </div>

                {errors.visibility && (
                  <p className=" text-xl text-blue-900 ">
                    {errors.visibility.type === "required" &&
                      "Please fill out this field"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.visibility && (
                      <p className="w-full text-end  text-xl text-blue-900  ">
                        {
                          ExamAddEditErrors?.response?.data?.errors
                            ?.visibility[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          )}
          {/** Lecture Image  */}
          <div className="flex w-full flex-col items-end gap-2">
            <label className="w-full" htmlFor="img">
              Upload Exam image
            </label>

            <input
              id="img"
              className="signin-inputs   w-full"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="img"
              {...register("img", {
                required: values ? false : true,
                validate: (value) => !(value[0]?.size > 50000000),
              })}
            />

            {errors.img && (
              <p className="mt-2 w-full text-end  text-xl text-blue-900">
                {errors.img.type === "required" &&
                  "Please add a photo of the Exam"}
                {errors.img.type === "validate" && "Maximum image size is 50MB"}
              </p>
            )}
            {
              //!-------server errors -----

              ExamAddEditErrors &&
                ExamAddEditErrors?.response?.data?.errors?.img && (
                  <p className="w-full text-end  text-xl text-blue-900  ">
                    {ExamAddEditErrors?.response?.data?.errors?.img[0]}
                  </p>
                )
            }
          </div>

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              Description{" "}
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className=" text-xl text-blue-900 ">
                {errors.description.type === "maxLength" &&
                  "The maximum number of characters is 155 characters"}
                {errors.description.type === "minLength" &&
                  "Please write at least 3 letters"}
              </p>
            )}
            {
              //!-------server errors -----

              ExamAddEditErrors &&
                ExamAddEditErrors?.response?.data?.errors?.description && (
                  <p className="w-full text-end  text-xl text-blue-900  ">
                    {ExamAddEditErrors?.response?.data?.errors?.description[0]}
                  </p>
                )
            }
          </div>
          {/** submit */}
          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditExam.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditExam.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>
                {ID ? "Edit " : "Add "} {quiz ? "Quiz" : "Exam"}
              </p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AdminAddEditExam;
